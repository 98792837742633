import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/es6-destructuring",
  "date": "2015-09-02",
  "title": "ES6 DESTRUCTURING",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "The destructuring assignment syntax is a JavaScript expression in ES6 that makes it possible to extract data from arrays or objects using a syntax that mirrors the construction of array and object literals."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Destructuring assignment allows us to have an expression like variable declaration on the left-hand side describing which values to extract from the right-hand side. Sounds a bit confusing? Let's look at the specific examples.`}</p>
    <h2>{`Array Destructuring`}</h2>
    <p>{` Let's say we have a value variable which is `}{`[1, 2, 3, 4, 5]`}{` and we want to declare variables that contain first three elements. Traditionally each variable would be declared and assigned separately like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var value = [1, 2, 3, 4, 5];
var el1 = value[0];
var el2 = value[1];
var el3 = value[0];
`}</code></pre>
    <p>{`Having these variables, our original `}<strong parentName="p">{`value`}</strong>{` might now be represented as `}<strong parentName="p">{` `}{`[el1, el2, el3, 4, 5]`}</strong>{` and, since we don’t care at the moment about last two values, as something like `}<strong parentName="p">{`[el1, el2, el3]`}</strong>{`.`}</p>
    <p>{`ES6 allows us to use this expression now on the left-hand side to achieve the same declaration as above:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var value = [1, 2, 3, 4, 5];
var [el1, el2, el3] = value;
`}</code></pre>
    <p>{`The right hand side doesn’t have to be a variable, we can omit `}<inlineCode parentName="p">{`value`}</inlineCode>{` declaration all together:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var [el1, el2, el3] = [1, 2, 3, 4, 5];
`}</code></pre>
    <p>{`The left-hand side doesn’t have to a declaration either, you can use already declared variables:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var el1, el2, el3;
[el1, el2, el3] = [1, 2, 3, 4, 5];
`}</code></pre>
    <p>{`This brings us to a neat little trick that was previously impossible in JavaScript with just two variables – swapping values.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`[el1, el2] = [el2, el1];
`}</code></pre>
    <p>{`Destructuring assignment can also be nested:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var value = [1, 2, [3, 4, 5]];
var [el1, el2, [el3, el4]] = value;
`}</code></pre>
    <p>{`Returning `}<inlineCode parentName="p">{`tuples`}</inlineCode>{` from functions in ES6 becomes more of a first class citizen and feels pretty natural:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function tuple() {
  return [1, 2];
}

var [first, second] = tuple();
`}</code></pre>
    <p>{`You can also ignore certain elements in the array by simply omitting variables where appropriate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var value = [1, 2, 3, 4, 5];
var [el1, , el3, , el5] = value;
`}</code></pre>
    <p>{`This makes it really neat for example to pull values out of regular expression matches:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var [, firstName, lastName] = "John Doe".match(/^(\\w+) (\\w+)$/);
`}</code></pre>
    <p>{`Taking it one step further, you can also specify default values:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var [firstName = "John", lastName = "Doe"] = [];
`}</code></pre>
    <Message type="warn" title="" content="Note that this only works for `undefined` values. In the following example **firstName** and **lastName** will be **null**.
  ```
  var [firstName = 'John', lastName = 'Doe'] = [null, null];
  ```" mdxType="Message" />
    <h3>{`Spread Operator`}</h3>
    <p>{`Spread operator is where things get really interesting. Spreads, otherwise knows as the `}<strong parentName="p">{`rest`}</strong>{` pattern allow you to grab `}<inlineCode parentName="p">{`remaining values`}</inlineCode>{` from the array. In the example below tail receives all remaining array elements which is `}<strong parentName="p">{`[4, 5]`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var value = [1, 2, 3, 4, 5];
var [el1, el2, el3, ...tail] = value;
`}</code></pre>
    <p>{`Unfortunately implementation of `}<inlineCode parentName="p">{`splats`}</inlineCode>{` in ES6 is somewhat primitive and only allows you to get the remaining elements. The following patterns, while being very useful, are not possible in ES6:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var value = [1, 2, 3, 4, 5];
var [...rest, lastElement] = value;
var [firstElement, ...rest, lastElement] = value;
`}</code></pre>
    <h2>{`Object Destructuring`}</h2>
    <p>{`Now that you have a pretty clear understanding of how array destructuring works, let's look at object destructuring. It works pretty much the same way, just for objects:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var person = {firstName: "John", lastName: "Doe"};
var {firstName, lastName} = person;
`}</code></pre>
    <p>{`ES6 allows you to pull object properties using variable identifiers that differ from the property name they refer to. In the example below, variable name will be declared with `}<strong parentName="p">{`person.firstName`}</strong>{` value.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var person = {firstName: "John", lastName: "Doe"};
var {firstName: name, lastName} = person;
`}</code></pre>
    <p>{`What if you have a more complex, deeply nested, object? Not a problem!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var person = {name: {firstName: "John", lastName: "Doe"}};
var {name: {firstName, lastName}} = person;
`}</code></pre>
    <p>{`You can throw in some array destructuring here as well:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var person = {dateOfBirth: [1, 1, 1980]};
var {dateOfBirth: [day, month, year]} = person;
`}</code></pre>
    <p>{`Just like when dealing with arrays you can also specify default values.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var {firstName = "John", lastName: userLastName = "Doe"} = {};
`}</code></pre>
    <p>{`This also only works for undefined values. In the following example `}<inlineCode parentName="p">{`firstName`}</inlineCode>{` and `}<inlineCode parentName="p">{`lastName`}</inlineCode>{` will be `}<inlineCode parentName="p">{`null`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var {firstName = "John", lastName = "Doe"} = {firstName: null, lastName: null};
`}</code></pre>
    <h2>{`Destructuring Function Arguments`}</h2>
    <p>{`Function arguments in ES6 could also be declared in a destructuring way. This comes in super useful for the ever-prolific options argument. You can use array and object destructuring together.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function findUser(userId, options) {
  if (options.includeProfile) ...
  if (options.includeHistory) ...
}
`}</code></pre>
    <p>{`Same looks and feels much better in ES6:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function findUser(userId, {includeProfile, includeHistory}) {
  if (includeProfile) ...
  if (includeHistory) ...
}
`}</code></pre>
    <p>{`ES6 destructuring brings in much-needed syntax modernizations to JavaScript. It improves readability and reduces the amount of code necessary for expressive declarations.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      